var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CCardBody",
    [
      _c("alert-section", {
        attrs: {
          successAlertMessage: _vm.successAlertMessage,
          dismissSecs: _vm.dismissSecs,
          dismissSuccessAlert: _vm.dismissSuccessAlert,
          errorAlertMessage: _vm.errorAlertMessage,
          showErrorAlert: _vm.showErrorAlert,
        },
      }),
      _c(
        "CCard",
        [
          _c("CCardBody", { staticClass: "px-1 pt-3 pb-0" }, [
            _c("div", { staticClass: "d-flex flex-wrap" }, [
              _c("div", { staticClass: "pr-2 mb-sm-0 mb-2" }, [
                _c("h5", { staticClass: "mt-1" }, [
                  _vm._v("Total: "),
                  _c(
                    "span",
                    { staticClass: "d-inline count bg-primary pb-1" },
                    [_vm._v(_vm._s(_vm.total))]
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "flex-grow-1" },
                [
                  _c("CPagination", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.pages > 1,
                        expression: "pages > 1",
                      },
                    ],
                    attrs: {
                      align: "end",
                      dots: false,
                      pages: _vm.pages,
                      "active-page": _vm.activePage,
                    },
                    on: {
                      "update:activePage": function ($event) {
                        _vm.activePage = $event
                      },
                      "update:active-page": function ($event) {
                        _vm.activePage = $event
                      },
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ],
        1
      ),
      _c("CDataTable", {
        attrs: {
          "clickable-rows": "",
          striped: "",
          hover: "",
          items: _vm.loadedItems,
          fields: _vm.fields,
          sorter: { external: true, resetable: true },
          "table-filter": { external: true, lazy: true },
          "column-filter": { external: true, lazy: true },
          itemsPerPageSelect: {
            external: true,
            values: [5, 15, 25, 50, 100, 250, 500],
          },
          "items-per-page": _vm.itemsPerPage,
          loading: _vm.loading,
          noItemsView: {
            noResults: "No filtering results are available!",
            noItems: "No menu group childs found!",
          },
        },
        on: {
          "update:itemsPerPage": function ($event) {
            _vm.itemsPerPage = $event
          },
          "update:items-per-page": function ($event) {
            _vm.itemsPerPage = $event
          },
          "row-clicked": _vm.rowClicked,
          "update:sorter-value": _vm.sorterValue,
          "update:table-filter-value": _vm.tableSearch,
          "update:column-filter-value": _vm.columnFilter,
          "pagination-change": _vm.paginationChange,
        },
        scopedSlots: _vm._u([
          {
            key: "id_",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  { staticClass: "move-item" },
                  [
                    _c("CIcon", {
                      staticClass: "align-bottom",
                      attrs: { name: "cil-move", "data-id": item.id },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "select",
            fn: function ({ item }) {
              return [
                _c(
                  "td",
                  [
                    _c("CInputCheckbox", {
                      attrs: { checked: item._selected, custom: "" },
                      on: { "update:checked": () => _vm.check(item) },
                    }),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "price_currency",
            fn: function ({ item }) {
              return [
                _c("td", [_vm._v(_vm._s(_vm._f("toCurrency")(item.Price)))]),
              ]
            },
          },
          {
            key: "actions",
            fn: function ({ item, index }) {
              return [
                _c(
                  "td",
                  { staticClass: "py-2" },
                  [
                    _c(
                      "CButton",
                      {
                        attrs: {
                          size: "sm",
                          to: {
                            name: "Edit Menu Group Child",
                            params: {
                              mnuExtGrpId: _vm.$route.params.mnuExtGrpId,
                              id: item.id,
                            },
                          },
                          color: "info",
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-align-left" } })],
                      1
                    ),
                    _c(
                      "CButton",
                      {
                        staticClass: "ml-1",
                        attrs: { size: "sm", color: "danger" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteItem(item, index)
                          },
                        },
                      },
                      [_c("CIcon", { attrs: { name: "cil-trash" } })],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("CPagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pages > 1,
            expression: "pages > 1",
          },
        ],
        attrs: {
          pages: _vm.pages,
          "active-page": _vm.activePage,
          align: "center",
        },
        on: {
          "update:activePage": function ($event) {
            _vm.activePage = $event
          },
          "update:active-page": function ($event) {
            _vm.activePage = $event
          },
        },
      }),
      _c("mc-spinner", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: { opacity: 0.8, mtop: 32 + _vm.mcSpinnerMarginTop + "px" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }