var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "CRow",
    [
      _c(
        "CCol",
        { attrs: { sm: "12" } },
        [
          _c(
            "CCard",
            [
              _c(
                "CCardHeader",
                [
                  _c("CIcon", { attrs: { name: "cil-object-ungroup" } }),
                  _c("h5", { staticClass: "d-inline ml-2" }, [
                    _c(
                      "h3",
                      { staticClass: "d-inline" },
                      [
                        _c("CBadge", { attrs: { color: "secondary" } }, [
                          _vm._v(
                            _vm._s(_vm._f("uppercase")(this.menuGroupName))
                          ),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" Menu Group Childs "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "card-header-actions" },
                    [
                      _c(
                        "CButton",
                        {
                          staticClass: "mx-2",
                          attrs: { color: "success" },
                          on: {
                            click: function ($event) {
                              return _vm.showRestaurantCategory()
                            },
                          },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "align-bottom",
                            attrs: { name: "cil-plus" },
                          }),
                          _c("strong", [_vm._v("Add New")]),
                          _vm._v(" Child From Restaurant Category "),
                        ],
                        1
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: {
                            color: "primary",
                            to: { name: "Add New Menu Group Child" },
                          },
                        },
                        [
                          _c("CIcon", {
                            staticClass: "align-bottom",
                            attrs: { name: "cil-plus" },
                          }),
                          _c("strong", [_vm._v("Add New")]),
                          _vm._v(" Menu Group Child "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("BackendTable", {
                attrs: {
                  successAlertMessage: _vm.successAlertMessage,
                  dismissSecs: _vm.dismissSecs,
                  dismissSuccessAlert: _vm.dismissSuccessAlert,
                  errorAlertMessage: _vm.errorAlertMessage,
                  showErrorAlert: _vm.showErrorAlert,
                },
                on: {
                  "update:successAlertMessage": function ($event) {
                    _vm.successAlertMessage = $event
                  },
                  "update:success-alert-message": function ($event) {
                    _vm.successAlertMessage = $event
                  },
                  "update:dismissSecs": function ($event) {
                    _vm.dismissSecs = $event
                  },
                  "update:dismiss-secs": function ($event) {
                    _vm.dismissSecs = $event
                  },
                  "update:dismissSuccessAlert": function ($event) {
                    _vm.dismissSuccessAlert = $event
                  },
                  "update:dismiss-success-alert": function ($event) {
                    _vm.dismissSuccessAlert = $event
                  },
                  "update:errorAlertMessage": function ($event) {
                    _vm.errorAlertMessage = $event
                  },
                  "update:error-alert-message": function ($event) {
                    _vm.errorAlertMessage = $event
                  },
                  "update:showErrorAlert": function ($event) {
                    _vm.showErrorAlert = $event
                  },
                  "update:show-error-alert": function ($event) {
                    _vm.showErrorAlert = $event
                  },
                },
              }),
              _c("RestaurantCategory", {
                attrs: {
                  successAlertMessage: _vm.successAlertMessage,
                  dismissSecs: _vm.dismissSecs,
                  dismissSuccessAlert: _vm.dismissSuccessAlert,
                  errorAlertMessage: _vm.errorAlertMessage,
                  showErrorAlert: _vm.showErrorAlert,
                },
                on: {
                  "update:successAlertMessage": function ($event) {
                    _vm.successAlertMessage = $event
                  },
                  "update:success-alert-message": function ($event) {
                    _vm.successAlertMessage = $event
                  },
                  "update:dismissSecs": function ($event) {
                    _vm.dismissSecs = $event
                  },
                  "update:dismiss-secs": function ($event) {
                    _vm.dismissSecs = $event
                  },
                  "update:dismissSuccessAlert": function ($event) {
                    _vm.dismissSuccessAlert = $event
                  },
                  "update:dismiss-success-alert": function ($event) {
                    _vm.dismissSuccessAlert = $event
                  },
                  "update:errorAlertMessage": function ($event) {
                    _vm.errorAlertMessage = $event
                  },
                  "update:error-alert-message": function ($event) {
                    _vm.errorAlertMessage = $event
                  },
                  "update:showErrorAlert": function ($event) {
                    _vm.showErrorAlert = $event
                  },
                  "update:show-error-alert": function ($event) {
                    _vm.showErrorAlert = $event
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }