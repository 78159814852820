var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "selection-panel" }, [
    _c(
      "div",
      [
        _c("h2", [_vm._v(_vm._s(_vm.label))]),
        _c("CDataTable", {
          attrs: {
            striped: "",
            hover: "",
            size: "sm",
            items: _vm.items,
            fields: _vm.fields,
            loading: _vm.loading,
            noItemsView: {
              noResults: "No filtering results are available!",
              noItems: "No menu found!",
            },
          },
          scopedSlots: _vm._u([
            {
              key: "select",
              fn: function ({ item }) {
                return [
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("CInputCheckbox", {
                        staticClass: "ml-1",
                        attrs: { checked: item._selected, custom: "" },
                        on: { "update:checked": () => _vm.select(item) },
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "Price",
              fn: function ({ item }) {
                return [
                  _c(
                    "td",
                    [
                      _c("CInput", {
                        staticClass: "mb-0",
                        attrs: {
                          lazy: false,
                          value: item.Price,
                          placeholder: "0.00",
                          type: "number",
                          step: "0.10",
                          pattern: "^\\d+(?:\\.\\d{1,2})?$",
                          onchange:
                            "(function(el){el.value=parseFloat(el.value).toFixed(2);})(this)",
                          disabled: item.eol == 1,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(item, "Price", $event)
                          },
                          input: (value) => {
                            //item._classes = 'table-selected';
                            //updateMenuItemPrice(item, value);
                            if (!item._selected && value) {
                              _vm.select(item)
                            }

                            if (item._selected && !value) {
                              _vm.deselect(item)
                            }
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-content",
                              fn: function () {
                                return [
                                  _c("CIcon", { attrs: { name: "cil-euro" } }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ]
              },
            },
          ]),
        }),
        _c(
          "CCard",
          { staticClass: "actions sticky-bottom" },
          [
            _c("CCardBody", { staticClass: "p-2" }, [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-wrap align-items-center",
                  staticStyle: { gap: "0.75rem" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "action d-flex w-50 flex-gap-2" },
                    [
                      _c(
                        "CButton",
                        {
                          attrs: {
                            disabled:
                              !(_vm.items.length > 0) ||
                              _vm.items.length == _vm.selectedItems.length,
                            color: "info",
                          },
                          on: { click: _vm.selectAllItems },
                        },
                        [_vm._v("Select All")]
                      ),
                      _c(
                        "CButton",
                        {
                          attrs: {
                            disabled:
                              !(_vm.items.length > 0) ||
                              _vm.items.length != _vm.selectedItems.length,
                            color: "danger",
                          },
                          on: { click: _vm.deselectItems },
                        },
                        [_vm._v("Deselect All")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }